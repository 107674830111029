import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import DefaultLayout from '../components/default-layout/default-layout';
import ContentWrapper from '../components/content-wrapper/content-wrapper';
import SEO from '../components/seo/seo';

const CartPage = ({ data }) => {
  return (
    <DefaultLayout data={data}>
      <SEO
        title="Cart"
        description=""
        siteConfig={data.site.siteMetadata}
        pageUrl="/cart"
      />
      <ContentWrapper>
        <iframe
          title='Cart'
          name='SpektrixIFrame'
          id='SpektrixIFrame'
          frameBorder='0'
          onLoad={(event) => event.target.className = 'loaded'}
          src={`${ data.site.siteMetadata.spektrixAPI }/${data.site.siteMetadata.spektrixClientName}/website/Basket2.aspx?resize=true`}>
        </iframe>
      </ContentWrapper>
    </DefaultLayout>
  );
};

CartPage.propTypes = {
  data: PropTypes.object
};

export default CartPage;

export const query = graphql`
  query CartPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
        spektrixClientName
        spektrixAPI
      }
    }
  }
`;
